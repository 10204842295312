import React, { useState } from "react";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2
// import Image from "react-image-enlarger";
import lunch from '../../images/Huicholitos Menu1024_1.jpg'
import lunch2 from '../../images/Huicholitos Menu1024_2.jpg'
import lunch3 from '../../images/Huicholitos Menu1024_3.jpg'


const itemData = [
    { title: 'Lunch', image: lunch },
    { title: 'Seafood Specialties', image: lunch2 },
    { title: 'Cookies', image: lunch3 }
]

const Slider = () => {
    const [current, setCurrent] = useState(0);
    const [zoomed, setZoomed] = React.useState(false);

    const nextSlide = (length) => setCurrent(current === length - 1 ? 0 : current + 1);
    const prevSlide = (length) => setCurrent(current === 0 ? length - 1 : current - 1);

    return (
        <div style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <FaChevronLeft className="leftArrow" onClick={() => prevSlide(itemData.length)} style={{
                position: "absolute",
                top: "50%",
                left: "20px",
                fontSize: "2rem",
                zIndex: zoomed ? 99 : 3
            }} />
            <FaChevronRight className="rightArrow" onClick={() => nextSlide(itemData.length)} style={{
                position: "absolute",
                top: "50%",
                right: "20px",
                fontSize: "2rem",
                zIndex: zoomed ? 99 : 3
            }} />
            {itemData.map((item, idx) => {
                return idx === current ? (
                    <Grid2 key={item.image} display="flex" >
                        <img
                            style={{ zIndex: 3 }}
                            zoomed={zoomed}
                            src={`${item.image}`}
                            srcSet={`${item.image}`}
                            alt={item.title}
                            loading={"lazy"}
                            onClick={() => setZoomed(true)}
                            onRequestClose={() => setZoomed(false)}
                        />
                    </Grid2>
                ) : ""
            })}
        </div >
    );
};

export default Slider;

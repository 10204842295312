// import LazyHero from 'react-lazy-hero'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import heroImage from '../images/IMG_7278.JPG'

const Hero = () => {
  const width = window.innerWidth
  const height = window.innerHeight

  return (
    <div className={'hero'} id={'Home'}>
      <Box
        sx={{
          position: 'relative',
          backgroundColor: 'grey.800',
          color: '#fff',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundImage: `url(${heroImage})`,
          top: 0,
          left: 0,
          marginTop: '100px',
          minWidth: '100%',
          minHeight: '100%',
          width,
          height
        }}
        className={"heroImageContainer"}
      >
        <Grid
          container
          spacing={0}
          justifyItems={'center'}
          alignContent={'center'}
          justifyContent={'center'}
          sx={{ minHeight: '100vh', backgroundColor: 'rgba(0,0,0,0.3)' }}>
          <Grid item xs={8} sm={9} md={9} lg={9}>
            <Typography sx={{ color: '#ff9e7a' }} component="h4" variant="h4" gutterBottom textAlign={'center'}>
              Welcome to our authentic Mexican Cuisine.
            </Typography>
          </Grid>
          <Grid item xs={8} sm={9} md={8} lg={8}>
            <Typography variant="h5" color="inherit" paragraph textAlign={'center'}>
              Enjoy our delicious food we love to make.
            </Typography>
            <Grid item xs={12} sm={12} md={12} lg={12} textAlign={'center'}>
              <Button className={"heroButton"}>
                <a href="tel:3605398890">
                  Give us a call: (360)-539-8890
                </a>
              </Button>{" "}
              <Button className={"heroButton"} style={{ marginTop: '1px' }}>
                <Link variant="subtitle1" href="https://www.doordash.com/store/23158037?utm_source=mx_share" target="_blank" rel="noreferrer noopener">
                  Order through DoorDash.
                </Link>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

export default Hero
